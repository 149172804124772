import {
  ISettingContainerBaseProps,
  SettingContainer,
  TSettingContainerOnSaveReturnType,
} from "src/components/Settings/Setting/SettingContainer"
import { SettingToggle } from "src/components/Settings/Setting/SettingToggle"
import { spacing } from "src/ui/spacing"

interface IToggleProps {
  trueVal: string | true
  falseVal: string | false
  disabled?: boolean
  toolTipText?: string
}

export function ParadiseSettingToggle({
  title,
  value,
  onSave,
  ...props
}: ISettingContainerBaseProps & {
  toggleProps?: IToggleProps
  onSave(newVal: string | boolean): TSettingContainerOnSaveReturnType
  value: string | boolean
  children?: ReturnType<typeof SettingContainer>[] | React.ReactNode
}) {
  return (
    <SettingToggle
      title={title}
      titleProps={{
        variant: "nano",
        color: "secondary",
      }}
      value={value}
      gap={spacing.XS2}
      onSave={onSave}
      {...props}
    />
  )
}
